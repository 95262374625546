import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import articles from '../pages/article-content'
import {Link} from 'react-router-dom'
import Sidebar from './SideBar'

const Articles=()=> {

  return(
    <>
    <Container fluid="md" dir="rtl" id='feature' style={{fontFamily:"Calibri", fontSize:17.5, marginTop:10, marginBottom:50}}>
      
    <h5 style={{padding:5, backgroundColor:'grey', color:'white' , textAlign:'righ'}}>یادداشتهای پراکنده</h5>
    <Row>
      <Col md={6}>
    {articles.map((article, key) => (
      <Link key={key} to={`/article/${article.name}`}>
          <h5>{article.title}</h5>
            <p style={{marginRight:5, textAlign:'right'}}>
            {article.content[0].substring(0, 150)}...
            </p>
          </Link>
          ))}
          </Col>
          <Col md={6}>
            <Sidebar />
          </Col>
        </Row>
    </Container>
    </>
)
}
export default Articles