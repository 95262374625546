import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const post2=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
      <img src="/imgs/couch.jpg" alt="couch" style={{marginTop:10}} />
       <h1>پوست دست یا چرم روی مبل، کدامیک؟</h1>
          <p style={{textAlign:'right'}}>
          اولین تصویری که با دیدن چنین تیتری در ذهن هر کس ممکن است پدید بیاید، پوست انسانی است که مانند چرم شده باشد. شاید انسانی که زیاد زیر آفتاب ایستاده و یا پیری و گذشت روزگار آنچنان مهر خود را بر جلد انسان کوبیده است که چرم نزدیکترین مقایسه‌ای است که می توان انجام داد.
          <br />
          <br/>
          داستان اما در اینجا کاملا چیز دیگری است. نه تبلیغاتی برای کرم پوست صورت و دست در میان است و نه ناهنجاری ناشی از اشعه تابش نور خورشید بر پوست انسانی. نگران نباشید این داستان به گفتگوی میان من و همسرم خواهد رسید اگرچه که ممکن است زیاد رمانتیک بنظر نرسد. موضوع اتفاقا در مورد چرم روی مبل است.
          <br />
          <br />
          یک روز آخر هفته بود و خلق و خوی من بر عکس روزهای دیگه که معمولا اضطراب در لحظه بیدار شدن به من هجوم می‌آورد، نبود. روز فوتبال بود و نگاهی به آسمان آبی پشت پنجره، حالت سرخوشی عجیبی به من داد. روی مبل خانه نشسته بودم، بله یک مبل چرمی و در عین تماشای فوتبال در تی وی، به تناول ناهار مشغول بودم. خانم تتمه غذای باقیمانده را در دیگی که در آن پخته شده بود، دوباره روی اجاق گرم کرد. مثل همیشه لطفش شامل ما شد و برای اضافه کردن به آنچه که درون بشقاب داشتم، به جلو آمد و سعی در پر کردن بشقابم کرد.
          <br />
          <br />
          من چشمی به تلوزیون داشتم و چشمی به بشقاب غذا و از گوشه چشم هم مراقب جلو آمدن خانم برای دریافت غذای بیشتر بودم. ناگهان زنگهای خطر در من بصدا درآمد. در یک لحظه متوجه شدم که در بی خبری و یا بی مبالاتی خانم، گرچه لطف و توجه‌اش در آن لحظه معطوف به حال من بود، دیگ داغی که تازه از روی اجاق بلند شده، آنچنان به پوشش چرمی مبل نزدیک شده است که در صورت تماس، قطعا سوراخ می‌شود و یا حداقل قطعا خسارتی جبران ناپذیر اتفاق می‌افتاد.
          <br />
          <br />
          در یک لحظه که دیگر برای همه چیز دیر شده بود، یعنی اخطار و یا بازداشتن لسانی و یا عملی دیگر غیرممکن بود، من توانستم دستم را بموقع حایل چرمِ مبل کرده و کف دستم را میان دیگ و مبل قرار دهم و تماسی که قرار بود میان دیگ داغ و چرم حاصل شود را به تماس دیگ آتشین و کف دستم تبدیل کنم. صدای جلیز و ولیز بلند شده خوشبختانه یا بدبختانه ناشی از تماس دیگ با پوست دستم بود و آسیبی به چرم نرسید. البته بشقاب غذایی که در دست داشتم، افتاد و بخشی از غذا بر روی پایم و مقداری دیگر بر روی مبل ریخت اما داغی آنچنان نبود که هیچکدام را بسوزاند. سوزشی اگر در آن لحظه بود، سوزش کف دست بود که کاملا آگاهانه انتخاب کرده بودم.
          <br />
          <br />
          گفتم که خوشبختانه یا بدبختانه صدای جلیز و ولیز در آمده ناشی از تماس دیگ داغ با پوست دستم بوجود آمد. خوشبختانه از آن جهت که آسیبی به مبل تازه خریداری شده نرسید. اما ممکن است که استنباط شما از کلمه بدبختانه سوختگی پوست دستم باشد. خیر، منظور من آن نیست.
          <br />
          <br />
          تصمیم در نجات مبل که به عملی سریع السیر توسط من انجامید، سرآغاز یکسری جدل و بحث داغ میان من و همسرم شد که کلمه بدبختانه دقیقا توضیح دهنده ماجراهای پس از آن است.
          <br />
          <br />
          همسر: “این چه کاری بود که کردی؟”
          <br />
          <br />
          من: “کدام کار؟”
          <br />
          <br />
          همسر: “سوزاندن دستت. نمی تونم این کارِت را درک کنم. این یک تصادف نبود، تو دستت را مخصوصا آوردی جلو که بسوزه.”
          <br />
          <br />
          من: “اوه اونو می گی. چیز خاصی نبود.”
          <br />
          <br />
          همسر: “منو نترسون. اگه داری شوخی می کنی، شوخی جالبی نیست. اگر هم که جدی این کار را کردی باید توضیح بدی. اگر مازوخیستی و از درد لذت می بری، چرا جلوی من اینکار را کردی؟ می تونستی در یک موقع دیگه اینکار را بکنی که من نبینم. منظورت از این که جلوی من خودت را بسوزونی چی بود؟ البته ممنون که جلوی من انجام دادی که بفهمم.”
          <br />
          <br />
          من: “نه بابا، مازوخیست کدومه. قصدم نجات مبل بود. تازه خریده بودیمش، حیف بود. نزدیک بود از بین بره.”
          <br />
          <br />
          جواب من انگار تازه ضربه اساسی را به همسرم وارد کرد. گویی که اگر اذعان می کردم که مازوخیستم، راحتتر می پذیرفت. از چشمهای گشاد و چانه پایین افتاده‌اش فهمیدم که ماجرا حالا حالاها تمام شدنی نیست. همان جا کنارم، همانطور که هنوز دیگ را نگاه داشته بود، نشست و من نگران بودم که دوباره احتمال تماس دیگ با چرم باشد. سوزش کف دست، محاسبه اینکه زمان گذشته از برداشته شدن دیگ از روی آتش هنوز امکان خسارت به چرم را دارد یا نه را از من گرفته بود اما در حین صحبتهای بعدی، گوشه چشمی به دیگ که البته هنوز فاصله قابل اعتمادی با مبل داشت را داشتم.
          <br />
          <br />
          همسر: “باورم نمی شه این حرف را می شنوم. تو دستت را سوزوندی که مبل خراب نشه؟”
          <br />
          <br />
          چشم از تلوزیون برداشتم و خیلی خونسرد گفتم، “البته. اولا که زیاد نسوخته و در ثانی پوست دست من قابل ترمیمه. اما چرم که خوب نمی شه، حیف مبل نو بود.”
          <br />
          <br />
          بحثهای بعد دیگر در محدوده سوختگی و چرم مبل و مازوخیسم نماند و به موضوعات بسیار بنیادینی رسید که تار و پود زندگی مشترک تازه پا گرفته شده ما را در هم ریخت.
          <br />
          <br />
          واقعه بوجود آمده همسر عزیز را به این نتیجه رساند که ما برای هم ساخته نشدیم و ریشه این اختلاف دیدگاه ناشی از نحوه بزرگ شدن هر کدام ما در خانواده‌هایی با فرهنگهای مختلف و وضعیت مالی متفاوت است که قطعا در آینده ای نه چندان دور در تصمیمات ریز و درشت منجمله فرزندانی که احتمالا در آینده خواهیم داشت، نه تنها موثر خواهد بود بلکه حرف اول را خواهد زد.
          <br />
          <br />
          همسر جان دیگ را روی زمین و بر روی موکت گذاشت و من در آن حالت سوزش دست و بمباران ذهنی توسط او مطمئن بودم که دیگ تا حالا حتما خنک شده است.
          <br />
          <br />
          در همان گیر و دار تماشای فوتبال و سوزش دست و شنیدن ملامتهای همسر جان، در ته ذهن مشغول بررسی دوران کودکی شدم و اینکه او پر بیراه هم نمی‌گفت. شاید اگر من در کودکی و بعد از آن دوران جوانی، زندگی بهتری به لحاظ مادی داشتم، شاید اکنون اینکار را نمی کردم.
          <br />
          <br />
          نگاه ملتمسانه مرا که دید گفت، “متاسفام اما من نمی توانم از چنین کسی بچه دار شوم.”
          <br />
          <br />
          چندی بعد که در تنهایی مشغول تماشای فوتبال بودم به یاد آنروز افتادم. هر وقت به یاد آن اتفاق می افتم، سعی می کنم به خودم بقبولانم که علت جدایی ما ترجیح من در سوختن موقتم بجای چرم مبل نبود. شاید هم بود. که می داند؟
          <br />
سپتامبر ۲۰۱۹
        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>

    </>
)
}
export default post2