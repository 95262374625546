import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const post5=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <img src="/imgs/bedroom.jpg" alt="bedroom" style={{marginTop:10}} />
       <h1>مهتابی</h1>
          <p style={{textAlign:'right'}}>
          چه زود هیجان اولیه آشنایی و سپس ازدواج تمام شده بود. سه سالی از ازدواجمان می گذشت و عشق سرجایش بود، اما هیجان رفته بود. تا آنکه در آن شب مهتابی ابراز احساسات بی¬سابقه ما در حین عشقبازی، هر دوی ما را غافلگیر کرد. مدتی پس از آشنایی، از بیان احساساتمان نسبت به یکدیگر دست کشیدیم. کم پیش می¬¬آمد که یک "دوستت دارم¬" میانمان رد و بدل شود، چه رسد به ابراز احاساسات. هم من و هم او.
          <br />
          <br />
آنشب در اوج عشقبازی گفت: "شانه¬های پهنت را خیلی دوست دارم. فکر می¬کنم شانه¬هایت زیباترین عضو بدنت است."
وقتی آنشب آن جمله را از زبانش شنیدم، شور و حرارت  جنسی در من افزایش یافت و بر تحرکم بر روی او افزود. و من بی اختیار اما از صمیم قلب به او گفتم، "دوستت دارم."
<br />
          <br />
 نور ماه از پنچره اتاق به درون و روی تختخواب می¬تابید و صورت او را که از پایین، به چشمهای من خیره شده بود، مهتاب گونه روشن می¬کرد. درخشش پوست صورتش در آن نور خاص، از نوعی دیگر و دگرگونه بود. گویی قبل از آن شب، آن چهره و اجزایش را که همه در اندازه¬ای مناسب و مهمتر از آن، در هماهنگی کامل با یکدیگر قرار داشتند، ندیده بودم، و چشمهایش را نیز. انگار هرگز به آن دو چشم سیاه و نافذ که اکنون کوچکترین جزئیاتش، حتی تا اندازه مردمک، را دقیق تشخیص می¬دادم، نظر نیافکنده بودم. نه، نشئه علف نبودم که اگر سر شب حتی پک کوچکی هم زده بودم، دیدن همه این جزئیات به این شفافی و برجستگی می¬توانست نشانی از تاثیر آن بوده باشد.
 <br />
          <br />
یادم نمی¬آید که در کودکی از دهان پدرم هم یک "دوستت دارم" خطاب به مادرم شنیده باشم. من شک داشتم که آیا آموزه¬های کودکی من علت عدم ابراز احساسات از جانب من بود و یا خجالت از بزبان آوردن احساس. اما اینکه همسرم چرا مانند دیگر زنان از بیان احساسش خودداری می¬کرد مرا به شک انداخته بود. آیا واقعا عشق او هنوز پا برجا بود؟ یا آنکه او هم با فرهنگ ابراز احساسات بزرگ نشده بود؟
<br />
***
          <br />
سالها قبل از ازدواجم، توی کالج یک کلاس تاریخچه جاز گرفته بودم که خاطره ای فراموش نشدنی برایم بوجود آورد. از همان کلاسهای انتخابی که دانشجویان برای افزودن به واحدهایشان می گیرند. معمولا این کلاسها تفریح است، خواه می¬خواهد تاریخ باشد و یا فلسفه و یا موسیقی. از همان شروع کلاس تاریخچه جاز، همه چیز مفرح و خوش احساس بود. استاد، خود جوانی ریلکس بود که گاهگداری پیانو و گیتار می¬زد. پیانو در کلاس بود و گیتار را گاهی با خودش با پوشش مخصوص سیاه به سر کلاس رنگ می¬آورد.
<br />
          <br />
 فضا، فضای سرزندگی و تفنن بود، اما علاوه بر همه اینها زیبایی هم در آن کلاس بود. زیبایی در کنار من نشسته بود. کاملا اتفاقی.
تا آنروز، زیبایی را به آنگونه که در چهره دختر کناری¬ام یافتم، ندیده بودم. تجسم محض زیبایی بود. او از یک پدر و مادر متمول روس در آمریکا بدنیا آمده و بصورت غیر حرفه¬ای برای مجله¬ها به عنوان مدل عکاسی کار می¬کرد. پاهای کشیده، هیکل باریک، صورت لاغر با گونه¬ها و لبهای برجسته¬، همه مانند مژگان بلندش کاملا طبیعی بودند. هیچ اثری از جراحی و یا تزریقی که بر افزودن قسمتی از اجزای چهره کمک کند، یافت نمی¬شد. سینه¬ها، نه کوچک بودند و نه بزرگ، فقط به اندازه مناسب، سفت و قلمبه، در زیر پیراهن و یا بلوزی که تا زیر گردن باریک و درازش را می¬پوشاند، نگاهها را همچون آهنربا به خود جذب می¬کرد. به هیچوجه در کلاس سکسی نمی¬پوشید. خود بخوبی به زیبایی¬اش واقف بود و نه تنها در پی نمایشش نبود، بلکه حتی سعی در مخفی کردنش نیز داشت، لااقل سر کلاس اینطور بود.
<br />
          <br />
و من، درست در کنار او، کاملا اتفاقی جای گرفته بودم. اولین روز کلاس، من بر روی آن صندلی نشسته بودم و او بعدا، در لحظه آخری که استاد، درب کلاس را بست، وارد شد. اکثر صندلی¬ها اشغال بود اما تک و توکی در جلو و میانه و بغلهای کلاس خالی بود. من در عقب کلاس بودم. همیشه عادت داشتم در عقب کلاس بنشینم. و یک صندلی در کنارم خالی بود. و او آمد و روی آن در کنارم نشست. وقتی خرامان از جلوی کلاس که به عقب آمد، سرها و نگاهها، چه مرد و چه زن، به سوی او برگشت. آنروز شلوار جین نسبتا تنگ با بلوز سفید رنگی که نه چسبان بود و نه گشاد، پوشیده بود.
<br />
          <br />
 وقتی کنارم نشست، حالت عجیبی بمن دست داد. یک نظر کافی بود که حالم را دگرگون کند. در حالی که از گرما می¬سوختم، در جا یخ زدم. چند بار به پشتی صندلی تکیه زدم تا عقب تر بروم و از پهلو، نیمرخش را نگاه کنم. وسطهای کلاس، دفتر یادداشتم را از کوله پشتی درآوردم و شروع به نوشتن در اوصاف زیبایی¬اش کردم. دست خودم نبود، باید می¬نوشتم. هرگز نویسنده نبودم و علاقه¬ای هم به نوشتن نداشتم. اما معلوم نبود که چرا در آن لحظه باید احساسم را بی¬اختیار در وصف آن آهو بره زیباروی و مه¬پیکر، روی کاغذ می¬آوردم. یک صفحه¬ را پر کردم.
 <br />
          <br />
  توجه اش به من جلب شد. کنجکاو بود که من چه یادداشتی از صحبتهای استاد، آنهم در روز اول کلاس که حرف خاصی هم نمی¬زد، برمی¬¬دارم. چشمش به روی کاغذ من افتاد و من فوری با دست رویش را پوشاندم و آن را مچاله کردم و در کوله پشتی گذاشتم. لبخندش به من، لبخندم را روی صورتم خشکاند. نیمرخ و تمام¬رخش هر کدام از دیگری زیباتر بود. اگر به عنوان داور، من باید حکم می¬دادم که کدامیک از زوایای چهره او، نیم رخش و یا تمام رخ او، زیباتر است، احتمالا تصمیم¬ گیری تا ابدیت طول می¬کشید.
  <br />
          <br />
تا به انتهای ترم، من تنها کسی در کلاس بودم که با او حشر و نشر داشتم. بیشتر همکلاسی¬های مذکر، مثل سگهای گرسنه، له له زنان، به من و او که در تنفس میان کلاس باهم بیرون می¬رفتیم، خیره می¬شدند. لعنتهایی که از ته دل همه به سوی من فرستاده می¬شد را حس می¬کردم. صاحبان همان چشمهای خیره، حتی جرات اینکه شانس خود را برای دوستی با او امتحان کنند، نداشتند. برخی از آنها بسیار از من خوشتیپ¬تر بودند اما اینکه چرا آن دلبر مه¬پیکرِ زیباروی مرا برای مصاحبت انتخاب کرده بود را نمی¬دانستم؟ هیچکس منجمله خودِ من نمی¬دانست که او در من چه می¬دید؟
<br />
          <br />
کلاس به پیش می¬رفت و نواهای جاودانی بلوز و بزرگان جاز در گوش من طنین دیگری داشت که از بهشت برایم پخش می¬شد. آهنگ مورد علاقه او اما از مایلز دیویس، "شاهزاده من روزی خواهد آمد،" بود. چند بار هم با هم به کافی شاپ یک کتابفروشی در همان اطراف می¬رفتیم، حرف می¬زدیم، کتابهار را نگاه می¬کردیم و قهوه می¬خوردیم. چند بار هم همان آهنگ مورد علاقه¬اش از مایلز دیویس در کافه پخش شد و من مطمئن نبودم که آیا آن شاهزاده من هستم یا او هنوز چشم انتظار دیگری است. سرانجام در آخرهای ترم بود که شاهزاده پیدایش شد. آن زیباچهر گفت، با یک وکیل پولدار ایتالیایی که از دوستان خانوادگی¬شان است، نامزد کرده است.
<br />
***
          <br />
چندی پس از آنشب مهتابی در یک بعدازظهر شنبه که با همسرم برای گشت و گذار معمول در مال قدم می زدیم، سری به همان کتابفروشی زدیم و هنگام نوشیدن قهوه، مجلات مختلف را ورق می زدیم. روبروی یکدیگر نشسته بودیم و هر کدام از ما مشغول خواندن بودیم. ناگهان همان آهنگ جادویی از مایلز دیویس از بلندگوی قهوه خانه کتابفروشی پخش شد. موجی سرد تمام بدنم را در زیر پوستم در نوردید. بی اختیار سرم را بلند کردم و دیدم که آن زیباروی کلاس جاز از محوطه کتابفروشی به درون کافه می¬آید. به فاصله دو قدم عقب تر از او، یک جوان خوش تیپ که مشغول صحبت با تلفنش، بدنبالش روان بود. نشسته، در جا خشکم زد. نزدیکتر شدند. صدای مرد همراهش را ¬شنیدم که به ایتالیایی حرف می¬زد. او با همان ظاهر و شاید هم زیباتر، با همان چهره¬ای که از او در خاطر داشتم به جلوی میز آمد و ایستاد. اصلا تغییر نکرده بود. نگاهم کرد و لبخند زد. گویا منتظر بود چیزی بگویم. من سکوت کردم گویی لبانم با چسب به هم دوخته شده بود. خواستم صدایی یا ناله¬ای را از اعماق سینه برآورم، اما نتوانستم. در چشمانش انتظار بود. کلمات در هنجره مرد همراهش در مسابقه¬ای پرهیجان از هم برای بیرون آمدن از هم سبقت می¬گرفتند.
<br />
          <br />
نا¬امید، عقبگرد کرد. در لحظه آخر، یاس را در چشمانش خواندم. به طرف قسمت خروجی از کافه رفت و مرد هم همچنان مانند سایه به دنبالش روان بود. به مرز ورود به کتابخانه رسیده بود. ناگهان دهانم باز شد و با صدایی بلند فریاد زدم: "دوستت دارم." برگشت و نگاهم کرد، لبخند زد و بیرون رفت و مرد همراهش نیز بدنبالش رفت.
همسرم در آنسوی میز کوچک همچون چند مشتری در اطرافمان با تعجب به من خیره شده بودند. دستم را دراز کردم و دست همسرم را در دستم گرفتم. دست دیگرش نیز را طلب کردم. در نگاهش تعجب و گیجی ادغام بود. لبخند زدم. به سویی که زیباروی از آنجا بیرون رفته بود نگاه کردم. داشت باز می¬گشت. تنهای تنها. مرد به دنبالش نبود. همسرم رد نگاهم را گرفت و به همان سو خیره شد. مه¬پیکر زیباروی به میز ما نزدیک شد، بطرف صندلی همسرم رفت و در آنجا نشست. زیباپیکر مهرو، با همسرم یکی شد و من دستانشان را در دست فشردم.
<br />
آوریل 2019

        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>
    </>
)
}
export default post5
