import React from 'react'
import {Navbar, Nav} from 'react-bootstrap'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from './Home'
import Contact from './Contact'
import index from './posts/story/index'
import asghari from './posts/story/asghari'
import couch from './posts/story/couch'
import life from './posts/story/life'
import snake from './posts/story/snake'
import moonlight from './posts/story/moonlight'
import phoenix from './posts/story/phoenix'
import ArticlesList from './ArticlesList'
import ArticlesListPage from '../pages/ArticlesListPage';
import ArticlePage from '../pages/ArticlePage';
import NotFoundPage from '../pages/NotFoundPage'
import editorial from './posts/story/editorial';


const Navig=()=> {

return(
  <>
      <Router>
      <Navbar bg="dark" variant="dark" sticky="top" dir='rtl'>
      
      <Navbar.Brand href="/" ><img src='./imgs/k1.png' alt="kaboli" width='80' height='80' style={{marginLeft:10}}/></Navbar.Brand>
        <Nav className="m-auto">
          <Nav.Link href="/">خانه</Nav.Link>
          <Nav.Link href="/index">داستان</Nav.Link>
          <Nav.Link href="/articlesList">یادداشتها</Nav.Link>
          <Nav.Link href="/contact">تماس</Nav.Link>
        </Nav>
      </Navbar>
      <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/index" component={index}/>
        <Route exact path="/ArticlesList" component={ArticlesList}/>
        <Route path="/articlelistpage" component={ArticlesListPage} />
        <Route path="/article/:name" component={ArticlePage} />
        <Route path="/editorial" component={editorial} />
        <Route path="/asghari" component={asghari} />
        <Route path="/couch" component={couch} />
        <Route path="/life" component={life} />
        <Route path="/snake" component={snake} />
        <Route path="/moonlight" component={moonlight} />
        <Route path="/phoenix" component={phoenix} />
        <Route exact path="/notfoundpage" component={NotFoundPage}/>


      </Switch>
      </div>
      </Router>
    </>
)
}
export default Navig