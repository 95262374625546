import React from 'react'
import {Container, Card, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';


const CardDeck=()=> {

  return(
    <>
    <Container fluid="md" id='feature' style={{fontFamily:"Calibri", fontSize:17.5}}>
    
    <Row style={{marginTop:10, padding:5}}>
        <Col>
        <Card>
        <h5 style={{color:'gray'}}>مطالب برگزیده</h5>
        <Card.Img variant="top" src="https://i0.wp.com/www.akhbar-rooz.com/wp-content/uploads/2022/10/106.jpg?resize=790%2C444&ssl=1" height='250' />
        <Card.Title><a href="https://akhbar-rooz.com/?p=175173" target="_blank">
        گسست نسلها و خیز راست افراطی برای هدایت انقلاب – کیوان کابلی</a></Card.Title>
        <Card.Text style={{margin:10}}>گسست نسلها یکی از کلیدی‌ترین مفاهیمی بود که خمینی، بنیانگذار جمهوری اسلامی آن را به عنوان طول بقای رژیمش شناخت و به دقت در راه رسیدن به آن برنامه ریزی کرد. از ابتدای استقرار نظامش، خمینی بدنام کردن اساتید بزرگ ادب و هنر معاصر ایران همچون فروزانفر، ناتل خانلری و محمد معین و دیگران را آغاز کرد.
        <a href="https://akhbar-rooz.com/?p=175173" target="_blank">ادامه مطلب در اخبار روز </a>
        </Card.Text>
        <br />
        <Card.Title><a href="http://www.iran-e-sabz.org/%da%a9%d9%84%d8%a7%d8%a8%e2%80%8c%d9%87%d8%a7%d9%88%d8%b3-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%db%8c%d8%a7%d9%86/" target="_blank">
          کلاب‌هاس ایرانیان</a></Card.Title>
        <Card.Text style={{margin:10}}>
        اتاقهای مجازی گوناگون با تیترهای مختلف برای سخنرانی، گفتگو و حرف زدن و شنیدن. اینجا کلاب‌هاس است با کاربران ایرانی از همه نقاط دنیا که در واکنش به این رسانه جدید اجتماعی، یا بر اساس علاقمندی به یک موضوع خاص در یک اتاق جمع می شوند و یا صرفا برای یافتن سوژه مورد علاقه‌شان از اتاقی به اتاق دیگر سرک می کشند.<br />
        <a href="http://www.iran-e-sabz.org/%da%a9%d9%84%d8%a7%d8%a8%e2%80%8c%d9%87%d8%a7%d9%88%d8%b3-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%db%8c%d8%a7%d9%86/" target="_blank">ادامه مطلب در سایت سبزهای ایران</a>
        </Card.Text>
        <br />
        <Card.Title><a href="https://www.iraniansforum.org/post/%d9%be%db%8c%d8%b4%db%8c%d9%86%d9%87-%d8%aa%d8%a7%d8%b1%db%8c%d8%ae%db%8c-%d9%85%d9%82%d8%a7%d9%88%d9%85%d8%aa-%d8%af%d8%b1-%d8%a8%d8%b1%d8%a7%d8%a8%d8%b1-%d8%a8%db%8c%d9%86%d8%a7%d8%af%da%af%d8%b1" target="_blank">پیشینه تاریخی مقاومت در برابر بنیادگرایی اسلامی</a></Card.Title>
        <Card.Text style={{margin:10}}>
        تظاهرات زنان افغانستان در کابل و شهرهای دیگر افغانستان برای دفاع از حقوق اولیه خود در مقابل طالبان، واکنشهای بسیاری در میان ایرانیان در داخل و خارج از کشور برانگیخت. بیشتر اظهار نظر کنندگان در فضای مجازی حامی بانوان افغانستانی هستند و برخی با ستایش و غرور آنها را مورد تمجید قرار می دهند.<br />
        <a href="https://www.iraniansforum.org/post/%d9%be%db%8c%d8%b4%db%8c%d9%86%d9%87-%d8%aa%d8%a7%d8%b1%db%8c%d8%ae%db%8c-%d9%85%d9%82%d8%a7%d9%88%d9%85%d8%aa-%d8%af%d8%b1-%d8%a8%d8%b1%d8%a7%d8%a8%d8%b1-%d8%a8%db%8c%d9%86%d8%a7%d8%af%da%af%d8%b1" target="_blank">ادامه مطلب در سایت فورم ایرانیان</a>
        </Card.Text>
        <br />
        
        <Card.Title><a href="http://www.iran-e-sabz.org/%d8%ac%d9%8f%d8%b3%d8%aa%d8%a7%d8%b1%db%8c-%da%86%d9%86%d8%af-%d8%af%d8%b1-%d8%a2%da%86%d9%85%d8%b2-%d8%b3%db%8c%d8%a7%d8%b3%db%8c-%d8%af%d8%b1-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%d8%8c-%d8%a7%d8%b2-%d9%86/" target="_blank">جُستاری چند در آچمز سیاسی در ایران، از نهضت مشروطه تا به امروز</a></Card.Title>
        <Card.Text style={{margin:10}}>شاید همه ما بارها از خود پرسیده‌ایم که چرا رژیمی تا این حد منفور در جامعه‌ای تا بدین حد ملتهب و برآشفته از نابسامانی‌های اقتصادی و معیشتی، توانسته قیامهای متعدد را پشت سر گذاشته و همچنان پس از چهار دهه به حیات نامیمون خود که به هر لحاظ سنخیتی با قرن کنونی ندارد، ادامه دهد؟<br />
            <a href="http://www.iran-e-sabz.org/%d8%ac%d9%8f%d8%b3%d8%aa%d8%a7%d8%b1%db%8c-%da%86%d9%86%d8%af-%d8%af%d8%b1-%d8%a2%da%86%d9%85%d8%b2-%d8%b3%db%8c%d8%a7%d8%b3%db%8c-%d8%af%d8%b1-%d8%a7%db%8c%d8%b1%d8%a7%d9%86%d8%8c-%d8%a7%d8%b2-%d9%86/" target="_blank">ادامه مطلب در سایت سبزهای ایران</a>
            </Card.Text>
        
          <Card.Title><a href="https://akhbar-rooz.com/?p=102486" target="_blank">ملک الموت</a></Card.Title>
          <Card.Body>
          <Card.Text>فرشته مرگ یا ملک الموت از آثار برجسته لویس بونوئل، فیلمساز اسپانیایی و خالق سبک سورئالیسم در سینما است. این اثر نیز که مهر سورئالیستی بونوئل را بر خود دارد همچون دیگر آثار او روایتی نرمال و طبیعی، بدانگونه که بیننده در فیلمهای دیگر جستجو می کند، ندارد.<br />
            <a href="https://akhbar-rooz.com/?p=102486" target="_blank">ادامه مطلب در سایت اخبار روز</a>
            </Card.Text>
          
          <Card.Title><a href="https://akhbar-rooz.com/?p=93613" target="_blank">ترامپ در ماه</a></Card.Title>
          
          <Card.Text>چه بر سرمان آمده است؟ چگونه به اینجا رسیدیم که مخالفان برانداز جمهوری اسلامی به شیفتگان ترامپ مبدل شده‌اند؟ عده ای او را دانالد شیر دل می‌خوانند و گروهی برای قدردانی از وی، اقدام به ضرب سکه ای می‌کنند که یکسوی آن کوروش و در سوی دیگر چهره ۴۵ مین رئیس جمهور آمریکا نقش بسته است.<br />
            <a href="https://akhbar-rooz.com/?p=93613" target="_blank">ادامه مطلب در سایت اخبار روز</a>

            </Card.Text>
            <Card.Title><Link to="/editorial">ضرورت گشایش بحث در باره رابطه دولت مرکزی و اقوام و ملیتهای مختلف در ایران (1)</Link></Card.Title>
            <Card.Text>
            سرکوب ترکمن صحرا، کشتار بی رحمانه اهالی کردستان توسط سپاه پاسداران تازه تاسیس، و قتل عام هموطنان عرب خوزستانی تحت رهبری تیمسار مدنی اولین مواردی نبود که بحث حقوق ملیتها و اقوام مختلف در ایران را به سطح جامعه آورد. ریشه های تاریخی رابطه اقوام و ملیتهای تحت ستم با دولت مرکزی  در دوران معاصر به پس از انقلاب مشروطیت و سپس دوران پهلوی باز می گردد.
            </Card.Text>
          </Card.Body>
        </Card>
        </Col>

      </Row>
      <Row style={{marginTop:20}}>
      <Col>
        <Card>
        <h5 style={{color:'gray'}}>تکنولوژی</h5>
          <Card.Img variant="top" src="./imgs/tech.jpg" height="150" />
          <Card.Body>
            <Card.Text>
              مطالب و مقالاتی در باره زمینه‌های گوناگون تکنولوژی، از بررسی داده‌ها تا ساختن وبسایت و غیره 
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <a target='blank' href="https://www.aboutdata.info/">مراجعه</a>
          </Card.Footer>
        </Card>
        </Col>
        <Col>
        <Card>
        <h5 style={{color:'gray'}}>داستانهای کوتاه</h5>
          <Card.Img variant="top" src="/imgs/life.jpg" height="150"/>
          <Card.Body>
            <Card.Text>
              داستانهای کوتاه و یا بخشهایی از رمانهای در دست انتشار که میتوانند بطور مستقل خوانده شوند.
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Link to="/index">مراجعه</Link>
          </Card.Footer>
        </Card>
        </Col>
        </Row>
      
    </Container>
    </>
)
}
export default CardDeck