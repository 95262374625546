import React from 'react'
import {Container, Image, Row} from 'react-bootstrap'
import { Timeline } from 'react-twitter-widgets'

const CardDeck=()=> {

  return(
    <>
    <Container fluid="md" style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:50}}>
    <h5 style={{padding:5, backgroundColor:'grey', margin:10, color:'white', width:200}}> دانلود کتابهای فارسی</h5>

        <Row style={{margin:10}} >
         <div> 
         <h5 style={{margin:10}}>چشمان مادرش</h5>
         <a target="blank" href="./eyes-Fa.pdf"><Image src="./imgs/eyesFa.jpg"  style={{marginLeft:90, marginBottom:10}}/></a>
         </div> 
      <div>
      <h5 style={{margin:10}}>قوم لوط و اصحاب ابراهیم</h5>
      <a target="blank" href="lotpdf.pdf"><Image src="./imgs/lot-fa.jpg" /></a>
      </div>
      <div>
      <h5 style={{margin:10}}>Woman Life Freedom</h5>
      <a target="blank" href="WLF.pdf"><Image src="./imgs/wlf.jpg" /></a>
      </div>
      </Row>
    <h5 style={{padding:5,backgroundColor:'grey',margin:10, color:'white', width:200}}> کتابهای منتشر شده به انگلیسی</h5>
    <Row style={{margin:10}}>
      <a target="blank" href="https://www.amazon.com/dp/B07GBQLZ16"><Image src="./imgs/children.jpg" style={{marginLeft:90, marginBottom:10}}/></a>
      <br />
      <a target="blank" href="https://www.amazon.com/dp/B081QX6D1Q"> <Image src="./imgs/lottery-199x300.jpg" /></a>
        </Row>
        <Row style={{margin:10}} >
        <a target="blank" href="https://www.amazon.com/dp/B07MB9FQ62"><Image src="./imgs/eyes-188x300.jpg"  style={{marginLeft:90, marginBottom:10}}/></a>
      <br />
      <a target="blank" href="https://www.amazon.com/dp/1676816372"><Image src="./imgs/Phaethon-199x300.jpg" /></a>
      <Timeline
        dataSource={{
          sourceType: 'profile',
          screenName: 'GetToSource'
        }}
        options={{
          height: '400'
        }}
      />
      </Row>
    </Container>
    </>
)
}
export default CardDeck