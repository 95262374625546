import React from 'react'



function Footer() {

  return(
    <>
        <div id="footer" >
          <div className="container text-center">
            <h6>
            ©2020 کیوان کابلی
              
            </h6>
          </div>
        </div>
    </>
  )
}

export default Footer