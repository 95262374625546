import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const editorial=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
      <img src="/imgs/satar.jpg" alt="satar" style={{marginTop:10}} />
       <h4>ضرورت گشایش بحث در باره رابطه دولت مرکزی و اقوام و ملیتهای مختلف در ایران (1)</h4>
          <p style={{textAlign:'right'}}>سرکوب ترکمن صحرا، کشتار بی رحمانه اهالی کردستان توسط سپاه پاسداران تازه تاسیس، و قتل عام هموطنان عرب خوزستانی تحت رهبری تیمسار مدنی اولین مواردی نبود که بحث حقوق ملیتها و اقوام مختلف در ایران را به سطح جامعه آورد. ریشه های تاریخی رابطه اقوام و ملیتهای تحت ستم با دولت مرکزی  در دوران معاصر به پس از انقلاب مشروطیت و سپس دوران پهلوی باز می گردد. باز کردن این بحث بسیار مهم در میان صاحبنظران، سازمانهای سیاسی اپوزسیون و کارشناسان امر از الزامات حیاتی دوران کنونی برای آینده و همزیستی مسالمت آمیز اقوام و میلیتهای ایران است.
          <br />
          <br />
این مباحث آنچنان عمیق و حساسند که گاه فقط استفاده از کلمه ملیت در برابر قومیت، عصبیت بسیاری از معتقدان این یا آن نظریه را بر می‌انگیزد. آنهم در زمانی که جمهوری اسلامی علاوه بر سرکوب زن و مرد ایرانی، شیعه و سنی و بهایی، فعال کارگری، حقوق بشری و محیط زیستی، به شدت پاسخ هرگونه خواسته ملیتهای مختلف را با سرکوب بیرحمانه می دهد.
<br />
          <br />
از یکسو طرفداران یک حکومت متمرکز، فعالان حقوق ملیتها را تجزیه طلب می خوانند و از آنسو، هواداران برقراری یک حکومت غیر متمرکز، تمرکز خواهان را فاشیست و همپالگی سرکوبگران اسلامی ارزیابی می کنند. البته طیفهای ملایمتر و سخت سر تری در میان این دو کمپ وجود دارند. در کمپ تمرکز خواهان، هم کسانی وجود دارند که مانعی در اعطای محدود برخی آزادیها برای امور خود گردانی را مجاز می شمارند و هم کسانی که حتی تکلم و آموزش زبان مادری اقوام را نوعی جهتگیری تجزیه طلبی معرفی می کنند. در کمپ مخالف نیز نظرات ملایم و تندروانه ای نیز موجود است. برخی آشکارا جدایی طلبند و تجزیه یک منطقه از ایران را تبلیغ می کنند و  برخی مبلغ فدرالیسم و اَشکال دیگری از همزیستی مسالمت آمیز هستند.
<br />
          <br />
این نظریات فقط مختص ایرانیان نیست. در کشورهای دیگر، حتی در حکومتهایی که انواع گوناگون دمکراسی  پیشاپیش در آنها نهادینه شده است، با این موضوع حساس مواجه بوده و هنوز هم هستند. به عنوان مثال، کشورهای کانادا، بریتانیا و اسپانیا، تاکنون راه حل مراجعه به آرای مردم را بر گزیده اند اما در یوگوسلاوی سابق، رو در رویی به خونریزی منجر شد و یا چکسلواکی به دو کشور مستقل چک و اسلواک تقسیم شد.
<br />
          <br />
تاریخ نشان داده است تقابل میان ملیتهای همزیست در چارچوب یک کشور هنگامی رشد کرده و به نقطه غلیان می رسد که آن کشور از یوغ استبداد و یا سلطه بیگانه رهایی یافته و پتانسیل خارج شده متناسب با تعادل قوا در احزاب و نیروهای سیاسی یا مذهبی در آن کشور، به رویارویی می‌انجامد. گاه این راه حل از میان خون می گذرد و قربانی بسیار می گیرد و گاه با گفتگو و تعامل، ملیتهای موجود راهی جدا از یکدیگر انتخاب می کنند و یا تصمیم می گیرند با حفظ حق و حقوق خود با یکدیگر به تعامل برسند و در یک چارچوب زندگی کنند.
<br />
          <br />
          از آنجاییکه کشور ایران طی 100 سال گذشته، بجز دورانهای بسیار کوتاه، همواره تحت حکومتهای دیکتاتوری قرار داشته، رسیدن به نقطه حل و فصل صلح آمیز و یافتن راه حل متناسب با حقوق هر یک از مناطق مختلف در کشور در مقاطعی مطرح شده است اما تاکنون بر پتانسیل ذکر شده در دورانهای مختلف سرپوش گذاشته شده است. بدون شک پس از رهایی ایران و ایرانی از وجود حکومت اسلامی موجود، این بحث سر باز خواهد کرد. از هم اکنون می توان با گفتگو در باره رابطه دولت مرکزی و  ملیتهای مختلف در ایران از بوجود آمدن تنش جلوگیری کرد.

        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>
    </>
)
}
export default editorial
