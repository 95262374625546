import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const post6=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <img src="/imgs/manWatchTv.jpg" alt="watchTv" style={{marginTop:10}} />
       <h1>قاف مثل ققونوس و ... قلی</h1>
       <h3>فصلی از رمان "فروشنده جغد"</h3>
          <p style={{textAlign:'right'}}>"تنها دلخوشی ققنوس مرگ است، برای آن که بتواند زاده شود ابتدا می خواهد که بمیرد. او هم فرزند خویش است، هم والد خویشتن و هم وارث خود. او هم دایه است و هم طفل، در واقع او خودش است ولی نه همان خود، زیرا او ابدیت حیات را از برکت مرگ به دست آورده است." Lactantius (250-317 AD)
          <br />
          <br />تلویزیون نزدیک شدن آتش به محله های اطراف را گزارش می کرد. خیره به شعله‌های آتش بر صفحه تی وی که یکی از کانالهای سن دیه‌گو از آتش سوزی‌های گسترده در جنوب کالیفرنیا به نمایش گذاشته، او را برای لحظاتی بر روی مبل بی‌حرکت کرده بود. او آتش را در درون خود و بیشتر بر سر و صورت خویش حس می‌کرد تا دیگر نقاط بدنش. این حس بخصوص را در گذشته نداشت. دقیقا نمی‌دانست که این گرمای درون، خوشحالی از نزدیک شدن آتش به محله‌ای که او در آن خانه داشت، است و یا نتیجه پکهای محکم و سریعی که بیست دقیقه پیشترش به قُلی زده بود؟
<br />
          <br />قلی، با ضمه قاف، مخفف قُل قُلی است. لوله نی متصل به بدنه بطری پلاستیکی آب، محلی است که فرد از آن طریق، دود ناشی از اتصال یک سیم داغ شده به تریاک را به درون ریه خود می‌فرستد. عمل دود شدن تریاک معمولا در راس بطری، محلی که درب پلاستیک بطری آب بسته می‌شود، صورت می‌گیرد. دود حاصله از بالای بطری به درون وارد شده و پس از گردش درون آبی که سطحش پایین تر از نی است، توسط فرد، کشیده می شود. در اثر مکِش، آب درون بطری به غلیان یا قُل قُل می افتد که اصطلاح قُل قُلی را بر این وسیله نسبتا ساده ولی مبتکرانه نهاده‌اند. 
 <br />
 واکنش انسانها در نخستین باری که تریاک می‌کشند، به این صورت است: هفتاد درصد آدمها اول بار، بالا می‌آورند. سی درصد بقیه اصلا نمی‌فهمند که چه شده و چه احساسی باید داشته باشند. این افراد انتظار دارند که بدن و ذهنشان مثل مصرف الکل واکنش دهد، حال آنکه در این انتظار خواهند ماند بدون آنکه تغییری در حال خود حس کنند. گروه اول اگر اقبال یاری شان کند، هرگز به آن دست نخواهند زد و گروه دوم، حیران از این که چرا نشعه را حس نکرده‌اند، احتمالا دوباره و سه باره به سراغش می‌روند و این ماده افیونی را امتحان می‌کنند. در دفعات بعد است که فرد کم کم نشعه این مخدر را در وجود خود حس کرده و به فراخور انتظار و موقعیتش دوباره آن را می‌کشد و یا می‌خورد. بعد از چند بار اول، تازه کشنده، یا خورنده می‌فهمد که چگونه آن را حس کند.
<br />
          <br />بهرام جزو گروه دوم بود و سالها پیش آن را امتخان کرده بود. همچنان نشسته بر روی مبل بژ رنگ یکسره بزرگی که براحتی سه نفر را در خود می گنجاند، بهرام از دوخته شدن چشم خود بر صفحه تلویزیون، گویی نشعه بیشتری را در خود می‌یافت. با وجود فاصله چندین متری از صفحه تی وی، می‌توانست گرمایش را احساس کند. "بله حتما باید گرمای آتش درون تلوزیون باشد." درون تلوزیون که نه اما آنچه که آن کانال تلوزیونی در آن لحظه مشغول نمایش آن بود، زبانه‌های آتش که درختان و بوته‌ها و بعضا خانه‌ها را می‌بلعید. حتی وقتی چند لحظه به جلو خم شد، گرما را بیشتر حس کرد و وقتی به مبل کاملا تکیه زد، احساس کرد صورتش خنک تر شده است. برای همین در جای خود شق و رق نشسته بود. نه حرکتی به جلو می‌کرد و نه به عقب. زاویه امتداد پایین ترین مهره نخاع، جایی درون لگن خاصره و رانهایش شاید دقیقا 90 درجه بود. در گذشته وقتی که داغ می‌کرد، دوست داشت صورتش را خنک کند اما الان، نه. دقیقا نمی‌دانست چه مدت در آن حالت نشسته است. اما یک چیز را خوب حس می کرد، حس یک التهاب مطبوع، جایی میان مرز بین آرامش از بی خیالی و زدن به سیم آخر، حسی که در چند هفته گذشته بشدت به آن نیاز داشت.
<br />
          <br />بحران اقتصادی سال 2008 و احتمال از دست دادن خانه‌اش که به خاطر عدم پرداخت قسط ماهیانه به بانک در شش ماه گذشته به جریان افتاده و زنگهای پی در پی از سوی کارکنان بانک برای اخطار به بازپس گیری منزل از طریق حراج، روح و روان بهرام را می‌آزرد. از خودش ناراحت بود. یکسال هم نمی شد که خانه را خریده بود. "چرا خریدم؟" پس از یکی دو ماه مدام از خود می پرسید. با همسرش که به تازگی از او جدا شده بود لج کرده بود و می خواست به او هم که شده ثابت کند، هنوز توانایی‌های بسیاری دارد، اما نداشت. خودش بهتر از همه می‌دانست که تهی شده است. همه چیزش را برای همسر سابق ودخترش بر جای گذاشته بود و دست خالی از خانه سابق بیرون آمده بود. حالا صُغی اجازه دیدن دخترش را هم نمی داد. "هِه، صُغی،" با نیشخندی نام همسر سابقش که مخفف صغری بود را چند بار تکرار کرد.
<br />
          <br />هر گزارشی مبنی بر نزدیک شدن آتش به آن محل، بر شعف بهرام می افزود. "چرا؟" از خود پرسید. به کنکاش درونش پرداخت، در حالیکه همچنان چشم بر آتش داشت و صورت هم. به همه چیز و هیچ چیز در آن واحد فکر می‌کرد. چطور ممکن است؟ تا به حال تا این میزان چند موضوع مختلف را به طور موازی و همزمان در ذهن خود بالا و پایین نکرده بود. شاید اثر تریاک بوده باشد. "شاید یعنی چه؟" به خود گفت، "حتما تاثیر آنست." به این نکته جدید اندیشید که حتما این ماده مخدر با کشتن سلولهای مغزش، تعداد بسیار دیگری را رها کرده است. و آن سلولهای رها شده اکنون در رقصی سماء وار به دور خود و دیگر سلولها، چنان قدرت فکری ایجاد کردند که او اکنون همه چیز را به روشنی می‌دید. چه باک از مرگ تعدادی سلول مغزی، اگر منجر به آزادی بخش دیگر شود؟ از این کشف تازه در باره سلولهای مغز که تقریبا هیچ چیز در باره آن نمی‌دانست، به خود بالید و خوشحال شد. در باره بلااستفاده بودن بخش بزرگی از مغز قبلا خوانده بود که انسانها شاید از چند درصد سلولهای مغزشان نیز در طول عمرشان استفاده نکنند. سالها بعد مقاله دیگری نیز در رد این نظریه نیز خوانده بود، اما الان ترجیح می داد که به همان تئوری اولیه باور داشته باشد، مخصوصا که به این دریافت تازه هم رسیده بود.
<br />
          <br />"صحنه، صحنه آتش است و خود را در آتش افکندن،" زیر لب زمزمه کرد اما برای لحظه‌ای بر افکارش ترمز زد. صحبت در آتش افکندن را نفهمید که چگونه به ذهنش جهیده، اما قطعا آن نبود که فکر می کرد. ناگهان ققنوس به افکارش خطور کرد. بله باید همو باشد. ققنوس در نظرش آمد. پرنده‌ای افسانه‌ای که همان سیمرغ بود. در لاتین فینیکس خطابش می‌کنند. در ذهن خود گفت: "چه فرق می‌کند که چه نامی دارد، عربی، فارسی و یا انگلیسی؟ ققنوس همان سیمرغ است."
به فکر حساسیت ایرانی‌ها در پاکسازی کلمات عربی در فارسی افتاد. بهرام ذوق شعری هم داشت. خود می پنداشت که شاعر است. همانطور که به آن مرد جغد فروش گفته بود، شاعر است. اما نبود. پدرش در ایران شاعر بود و او نیز تلاش زیادی کرد که مثل پدر شاعری کند اما چیزی از او بیرون نمی آمد.  بهرام فقط می خواند، هر چه که بدستش می رسید می خواند. وقتی نشعه می شد، کتاب اخوان را بدست می گرفت و با صدای بلند می خواند. پدرش اخوان را به عنوان بزرگترین شاعر ایران می‌دانست و از کودکی برای بهرام قطعات شعرهای اخوان را با صدای بلند قرائت می‌کرد. خود بهرام مخصوصا از شعر شوش و زبان فاخر و ضرباهنگ کلام اخوان لذت می برد. دیگر این شعر بلند را به خاطر سپرده بود. شوش را دیدم، این ابر شهر، این فراز فاخر، این گل میخ...

 <br />
          <br />برای یک لحظه توقف کرد و دوباره ترمزی به افکارش زد. ناگهان به یاد مرد جغد فروش افتاد. از خود پرسید، "مرد جغد فروش؟" فکر کرد که خواب دیده و کسی برای دیدنش و برای فروش جغد نیامده بود. اما آمده بود. همان مردی که او بی مهابا به درون آورده بودش و چند پک با او زده بود. در آنروز برای یک لحظه از دیدن یک مرد با جغدی در دست، در آستانه در ورودی شوکه شده بود. فردای آنروز فکر کرد که تمام ماجرا خواب و خیالاتی بیش نبود، اما بعد خود را قانع کرد که آن مرد واقعا در خانه او و در کنار او در آشپزخانه ایستاده و با هم حرف زده بودند.
  <br />
          <br />تکانی به خود بر روی مبل داد و با جابجا شدن بر روی مبل رشته افکار چند لحظه پیش را پی گرفت. کلمه پاکسازی در ذهنش طنین انداخت. ابتدا فکر کرد که داشت در باره پاکسازی پدرش از اداره فرهنگ فکر می‌کند اما چشمش که به صحنه آتش درون تی وی افتاد، دوباره به یاد ققنوس و سیمرغ و پاکسازی کلمات فارسی افتاد. با خود گفت، "چرا پاکسازی؟ آخوندی که بر شما ستم روا می کند، مانند خود شما فارسی حرف می زند. بسیاری از آنان عربی هم بلد نیستند. مهم این نیست که چه بنامیمیش آن پرنده افسانه ای را. مهم اینست که آن پرنده می‌سوزد و از خاکستر خود دوباره زاده می شود. عمری جاودانه دارد بدون آنکه جنیست مشخصی داشته باشد. نه مرد است و نه زن. خود، خویشتن خویش را می زاید، در فرایندی که عظیم است. اینکه چگونه در آتش می رود، حرف و حدیث فراوان است، بسته به آنکه از کجا و چه زبان و فرهنگی نقل شود. گاه به آتش می‌افتد و گاه خود وقتش که رسید خود را به درون آتش می افکند." از اینکه کلمات اینگونه ادیب وار در ذهنش ردیف شدند، به خود بالید.
<br />
          <br />"در آتش افکندن خود؟" خود را در آتش دید و بلافاصله این سوال که چرا چنین چیزی در آن لحظه به فکرش زده؟ خود در عجب بود. در سخت ترین شرایط هرگز فکر خودکشی به مخیله‌اش هم خطور نکرده بود. ذهن خود را به چالش گرفت.
<br />
          <br />دوباره کلمات را در ذهن خود می شنید اما اینبار در ضمیر سوم شخص مفرد. "حال گیریم که این فکر در این لحظات که همه چیز و همه جا، از درون وجود و سر و صورت گرفته تا درون تی وی و پیرامون خانه که آتش است، به فکرش زده باشد. چرا مرگی چنین سهمناک را پذیرا باشد؟ راههای آسانتری نیز هست. شاید احساس می کرد که ققنوس است. شاید از این طریق دوباره زاده شود. اما او نمی خواست که دوباره زاده شود." با خود و از زبان خود گفت، "مگر اینباری که بدنیا آمدم چه گلی به سر کی زدم که بخواهم دوباره بیایم؟"
<br />
          <br />برای آنکه از فکر مردن آنهم در آتش سوختن که برای لحظاتی بدنش را به ارتعاش درآورده بود، بیرون بیاید ابتدا به فینیکس و تفاوتش با ققنوس اندیشید اما در عین حال حوصله درگیر کردن ذهن خود در مورد تفاوت زبانی و فرهنگی سیمرغ با ققنوس را هم نداشت. اکنون که آتش و گرما، شعف و شور درونی به اوج رسیده بود، فقط می توانست به جنبه آتشین ققنوس بیاندیشد. ققنوس بود که به میان آتش رفته بود و از آنسوی دیگر پاک و مطهر بیرون زده بود. حتما سیمرغ باید مراحلی را طی کرده باشد که به آخر خط رسیده باشد. بعد از 500 سال 1000 سالی که می گویند عمر می کند، بسته به اینکه کی و در کجا در باره آن مرغ افسانه ای داد سخن سر داده باشد. 
<br />
<br />
با خود گفت، "خودش وقتش را حتما می داند. حالا گیریم که 500 سال بشود 550 سال، چه فرق می کند؟ احتمالا مهم اینست که خود مرغ فهمیده که آخر خط است." می دانست که این همه تفکرات بی معنی از تاثیرات نشعگی است. حوصله اش از همه چیز سر رفت. 
<br />
<br />
ناگهان زنگ در با صدای بلندتر از معمول به صدا درآمد. و پس از چند لحظه شنید که کسی محکم به در می‌کوبد. تمام پیکر بهرام گویی درجا یخ زد. چند بار دیگر صدای کوفته شدن دستی بر در ورودی خانه را شنید. آهسته از جا برخاست و بی آنکه صدایی از گامهایش بلند شود، بسوی در ورودی رفت. چشم خود را آهسته به پشت سوراخ کوچک تعبیه شده بر  روی در قرار داد. پلیس بود. پلیس دوباره از آنسوی در بر در کوفت و چرت بهرام را در اینسوی در پراند.
<br />
<br />
"اسکاندیدو پی دی Escondido PD،" صدا را از آن سوی در شنید. بهرام، رنگ از رخسار پریده، اکنون پیشانی را بر در گذاشته و چشمانش را بسته بود.
<br />
<br />
به صدایی که به آنسوی در برسد گفت: "آفیسر، من هیچ نقشی در ناپدید شدن آن مرد ندارم. من تا به حال حتی در جلسات ماهیانه صحابخانه‌های این خیابان هم شرکت نکردم. من از هیچ چیز اطلاعی ندارم."
<br />
<br />
سکوت برقرار شد. بهرام یک لحظه فکر کرد که دیگر کسی در آنسوی نیست و پلیس رفته است. از درون سوراخ دوباره نگاه کرد. همان پلیس را دید که در پشت در ایستاده و متعجب به روبرو خیره شده، گویی بهرام را از ورای دری که میانشان حایل است، می بیند. پس از لحظه ای پلیس گفت:
<br />
<br />
"در باره چی صحبت می‌کنی؟ می‌دونی که باید خانه را تخلیه کنی. آتش به نیم مایلی محل رسیده. کسی گزارش داده که ساکنان این واحد مسکونی هنوز اینجا را ترک نکردند."
<br />
<br />
خیال بهرام کمی آرام گرفت اما نشعگی گویا کلا از سرش پریده بود. دیگر در ذهنش نه ققنوسی بود و نه فکر آتش سوزی و نه تن به آتش سپردن.
<br />
<br />
بهرام: "اوکی آفیسر. دارم وسایل مختصری جمع می کنم که برم. چند دقیقه دیگه می رم."
<br />
<br />
پلیس: "اگر ماشین نداری من می تونم ببرمت."
<br />
<br />
بهرام: "نه آفیسر. ممنونم. ماشینم توی گاراژه. تا پنج دقیقه دیگه می‌رم."
<br />
<br />
بهرام دست به پیشانی کشید. دستش را که خیس عرق شده بود با پیرهنش پاک کرد و بعد لای موهایش فرو برد. از ققنوس درون ذهنش اثری باقی نمانده بود اما به قلی نگاه کرد که کنار دستشویی آشپزخانه شق و رق ایستاده بود. احساس کرد به چند پک دیگر نیاز دارد. هنوز مطمئن نبود که آیا قصد تخلیه را دارد.
<br />

اوت 2018

        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>
    </>
)
}
export default post6
