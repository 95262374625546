import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const post4=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
      <img src="/imgs/snake.png" alt="snake" style={{marginTop:10}} />
       <h1>خواب مار</h1>
          <p style={{textAlign:'right'}}>
          عجیب بود. خواب عجیبی بود. از آن خوابهایی که صبح پس از بیدار شدن تا ظهر هنوز به یاد انسان می ماند. و یا در بعداظهر با کمی فشار به یاد می آید. خواب مار بود. یادم نمی آید که مار در دستان کدامیک از آشنایان بود. اگر همانموقع که از خواب برخواستم، به جزئیاتش فکر کرده بودم، احتمالا یادم می ماند، اما الان فقط حسی از آشنایی آن شخص در ذهن مانده که سر مار را در میان دستانش نگاه داشته بود و به من نشانش می داد. کله را با دو انگشت از دو طرف چنان فشار داده بود که دهان مار باز شده بود. دو دندان نیش ترسناک. و آن نفر، همانی که احساس آشنایی اش همچنان در ضمیرم مانده و نمی توانم بجا بیاورم، گفت که می  خواهد زهرش را بکشد.و کشید، اما یادم نمی آید که دیده باشم کشیده باشد. فقط پس از عمل، مار را در حوله سفیدی گذاشته، به طوری که سرش، فقط سرش از میان حوله پیدا بود. مانند طفلی که حمام کرده و در حوله پیچیده شده باشد. چهره مار نشان از خستگی داشت. داشت به خواب می رفت. دیگر وحشت در چهره اش نبود، نه برای خودش و نه برای آنکس که او را می نگریست. آرام آرام در حال خفتن بود. همان آشنا که اکنون فقط دستانش در ذهنم مانده، حوله را با مار که سرش هویدا بود، روی یک میز گذاشت. چهره مار داشت دوست داشتنی می شد، مثل حیوان خانگی که با آن انس گرفته باشی.
          <br />
          <br />
و صحنه عوض شد. مار بزرگ شده بود. دیگر مار کامل نبود. نه اینکه مار نبوده باشد، بود، اما سرش فقط مار بود، با بدنی که در حال تکامل بود. سر مار با بدنی که داشت مثل انسان می شد. دو پا در آورده بود. احساس انس گرفته بود، هم خودش به من و هم من به او. شانه هایش داشت پهن می شد، اما سر همان سر بود و بدن، پولکی. و چند دیدار دیگر در چند حالت دیگر. اگر همان صبح بعد برخواستن از خواب به رشته تحریر درآورده بودم، قطعا صحنه ها جزئیات بیشتری می یافت. اما اکنون فقط صحنه هایی از رشد انسان گونه مار در ذهن مانده و آن احساس نزدیکی که داشت به خویشاوندی تبدیل می شد.
<br />
<br />
و در صحنه آخر هم سرش داشت به انسان تغییر می یافت. نه کاملا، اما به جوان خوشتیپی ماننده بود که هنوز شکل مار را در چهره اش می دیدی. ماری خوش چهره و جذاب. آری موی سرش هم در حال رشد بود. ماری خوش چهره ای که مو هم داشت. و دختر جوان به اتاق آمد، دست در دست همان مار که اکنون کاملا به انسان شبیه بود. شبیه که نه، اصلا خود انسان شده بود. دختر را در جایی دیده بودم. او هم آشنا بود، و اکنون دست در دست مار داشت. نمی دانستم که آیا می داند آنکه دست در دستش دارد، زمانی مار بوده است و یا تازه با او آشنا شده است. آیا جناب مار به او گفته که قبلا مار بوده و یا از او مخفی کرده است. مطمئن بودم که جایی در بدن آن جوان خوشتیپ، احتمالا در پشتش، زیر آن پیراهنی که بر تن دارد، جایی هنوز پولکی بر پوست پر طراوتش باقی مانده است.
<br />
          <br />
 به دختر با ایما و اشاره و یا صراحتا، به یاد ندارم کدام، رساندم که آیا پشتش را دیده است؟ دختر بدون هیچ معطلی دست در پیراهن او کرد و پشتش  را لمس کرد و سپس نشانم داد. نشانی از هیچ  فلس یا پولک نبود. پوست تازه بود. تازه و سرخ و پر نشاط. احتمالا مدتی زیادی از پوست انداختنش نگذشته بود. چه می توانستم بگویم؟ به آن دختری که هنوز بیاد ندارم که که بود و احساس نزدیکی بیشتری با او می کردم بگویم آنکس که با اوست، قبلا مار بوده؟ با جناب مار هم انس داشتم، اما نه آنگونه که با آن دختر آشنا. هر چه نباشد، آن مار زمانی همچون حیوان خانگی بود و پیشترش هم حیوان ترسناک. اکنون که پوست انداخته و تکامل یافته و به هیبت انسانی جذاب در آمده، آیا خوی و خصلت مار دارد یا انسان؟ تناقضی درونم را انباشت. به دختر بگویم و یا نه. اگر آری، چه بگویم؟ برای چه بگویم؟ من چه دینی به آن دختر دارم؟ تازه اگر هم گفته باشم، چه تاثیر خواهد داشت. فقط این می شود که به سلامت عقلم شک می کند. باید خودش تجربه کند. شاید این انسان خوش خط و خال کنونی که سابقا مار بوده، آنچنان هم بدتر از انسانها نباشد. بخصوص که حالا خودش کاملا انسان شده.
 <br />
          <br />
و قبل از آنکه چیزی بگویم، رفتند. با هم و دست در دست یکدیگر رفتند. نمی دانم به کجا؟ اما آنجا، جایی که من در آن بودم را ترک کردند. و من از خواب برخواستم.
غروب آنروز دستی به سینه ام کشیدم. اثری از خال زگیل مانندی که درست روی چاله سینه ام داشتم، نبود. همان نقطه ای که اگر ضربه‌ای بر آن وارد شود، نفس بند می‌آید. خال بزرگ افتاده بود. پوست انداخته بودم.
<br />
مه 2018
        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>
    </>
)
}
export default post4
