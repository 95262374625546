import React from 'react'
import {Container,Row, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Feature from './Feature'
import SideBar from './SideBar'
import articles from '../pages/article-content'
import '../App.css'

const Home=()=> {

  return(
    <>
    <Container fluid="md" dir='rtl'>
      <Row>
        <Col md={6} style={{backgroundColor:"#F6F6F6 "}}>
        <Feature />
        <h5 style={{padding:5, backgroundColor:'grey', color:'white' , textAlign:'righ'}}>یادداشتهای پراکنده</h5>
        {articles.map((article, key) => (
      <Link key={key} to={`/article/${article.name}`}>
          <h5>{article.title}</h5>
            <p style={{marginRight:5, textAlign:'right'}}>
            {article.content[0].substring(0, 150)}...
            </p>
          </Link>
          ))}
        </Col>
        
        <Col  md={6} >
          <SideBar />
        </Col>
        </Row>
    </Container>
    </>
)
}
export default Home
