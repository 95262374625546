import React, { useEffect } from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap'
import NotFoundPage from './NotFoundPage';
import articleContent from './article-content';
import {Share} from 'react-twitter-widgets'
import '../App.css'

const ArticlePage = ({ match }) => {
    const name = match.params.name;
    const article = articleContent.find(article => article.name === name);
    const url = 'https://kaboli.net/'+ article.name
    const title = article.title

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetch(`/api/articles/${name}`);
            
        }
        fetchData();
    }, [name]);

    if (!article) return <NotFoundPage />

    return (
        <>
        <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
            <Row>
            
      <Col md={6} >
        <h1>{article.title}</h1>
        <Share url={url} title={title} img={"../imgs/couch.jpg"}/>
        {article.content.map((paragraph, key) => (
            <p key={key}>{paragraph}</p>
        ))}
        </Col>
        <Col md={6}>
            <h5 style={{padding:5, backgroundColor:'grey',margin:10, color:'white', width:200}}> دانلود کتابهای فارسی</h5>
        <Row style={{margin:10}} >
         <a target="blank" href="./eyes-Fa.pdf"><Image src="../imgs/eyesFa.jpg"  style={{marginLeft:90}}/></a>
      <br />
      <a target="blank" href="lotpdf.pdf"><Image src="../imgs/lot-fa.jpg" /></a>
        </Row>
    <h5 style={{padding:5,backgroundColor:'grey',margin:10, color:'white', width:200}}> کتابهای منتشر شده به انگلیسی</h5>
    <Row style={{margin:10}}>
      <a target="blank" href="https://www.amazon.com/dp/B07GBQLZ16"><Image src="../imgs/children.jpg" style={{marginLeft:90}}/></a>
      <br />
      <a target="blank" href="https://www.amazon.com/dp/B081QX6D1Q"> <Image src="../imgs/lottery-199x300.jpg" /></a>
        </Row>
        <Row style={{margin:10}} >
        <a target="blank" href="https://www.amazon.com/dp/B07MB9FQ62"><Image src="../imgs/eyes-188x300.jpg"  style={{marginLeft:90}}/></a>
      <br />
      <a target="blank" href="https://www.amazon.com/dp/1676816372"><Image src="../imgs/Phaethon-199x300.jpg" /></a>

      </Row>
        </Col >
        </Row>
        </Container>
        </>
    );
}

export default ArticlePage;