import React from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer'
import Navig from './components/Navig'

function App() {
  return (
    <>
    <div className="App">
      <Navig />

      <Footer />
    </div>
    </>
  );
}

export default App;
