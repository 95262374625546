import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Sidebar from '../../SideBar'

const post3=()=> {

  return(
    <>
    
      <Container id="post1" fluid="md" dir='rtl' style={{fontFamily:"Calibri", fontSize:17.5, marginBottom:70}}>
        <Row>
      <Col md={{ span: 6, offset: 3 }}>
      <img src="/imgs/life.jpg" alt="life" style={{marginTop:10}} />
       <h1>زندگی</h1>
          <p style={{textAlign:'right'}}>
          سوار بر دوچره، رکاب زنان به دنبال پسری که با سرعت در جلوی من حرکت می‌کرد، به سوی مدرسه می‌رفتیم. دیر شده بود و من عقب و عقب تر می‌افتادم. در میان درختان پا می‌زدیم و پس از مدتی دیگر ندیدمش.
<br />
<br />
دیر شدن، تاخیر، موانع متعدد بسیار برای رسیدن به جایی که باید برسی اما هر چه تقلا می کنی، نمی رسی …
<br />
<br />
در آغاز بدنبال آن پسر با قدرت تمام پا می زدم، همسن او بودم، زمانی مابین اواخر دبستان و قبل از دبیرستان. او را در میان درختان و زمین ناهموار گم کردم و بعد در شهر بودم، به همراه دوچرخه‌ام. با دوچرخه به درون اتوبوس رفتم. آنرا در مرکز اتوبوس که فضای بازتری داشت، گذاشتم و بر روی یک صندلی در عقب اتوبوس نشستم. جمعیت زیاد نبود اما در ایستگاههای بعد ازدحام بیشتر شد. فکر کردم ایستگاهی که باید پیاده شوم را بلدم.
<br />
<br />
اکنون در همان سنی که به دنبال آن پسر دوچرخه سواری می‌کردم، نبودم. به دانشگاه می‌رفتم، حتی یادم می آید که کالج هم نبود، خود دانشگاه بود. دانشگاه معروفی هم بود، نه خیلی معروف، متوسط. اتوبوس شلوغ و شلوغتر می شد و جوانان مختلف که معلوم بود آنها هم دانشجو هستند، به درون می‌آمدند. چند دختر به عقب آمدند و خود را در کنار من فشردند. دختر جوانی تقریبا در بغل من جای گرفت. جوان بود، می‌دانستم که جوان است، البته نه آنچنان جوان. حس می کردم که سالها از او پیرترم. هنگام توقف در یکی از ایستگاهها، پس زمینه سیاهرنگی در پشت شیشه اتوبوس، طرح صورتم را انعکاس داد. خودم را دیدم. می دانستم که جوانتر از سنم نشان می‌دهم اما نه آنقدر که خودم ندانم و دیگران حدس نزنند.
<br />
<br />
نگران شدم، نگران رسیدن به ایستگاه مورد نظر برای پیاده شدن و رفتن به سر کلاس. باز حس دیر بودن، وجودم را گرفت اما اینبار با طعم شیرین بودن با کسی که در برم بود. مضطربانه به بیرون و توقفها نگاه می‌کردم و نمی‌شناختم. یا لااقل می دانستم که ایستگاه من نیست. دختر چسبیده به من متوجه نگرانیم شد. ریزه بود. آنچنان که گویی ابعاد من جثه کوچک او را بتمامی در خود گرفته بود. دل را بدریا زدم و از او مقصد را پرسیدم. می‌دانست که کجاست، اما نه در آن لحظه، چرا که خود برای جستن به بیرون نگاه کرد.
<br />
<br />
گفت که هنوز نرسیدیم و بعد نگاهی دوباره به بیرون انداخت. انگار به گفته خود شک داشت. تنها شیرینی وجود او بود که از درجه اضطرابم می‌کاست که گویی ایندو در من در جدالی سخت یکدیگر را به عقب می‌راندند و من بسته به آنکه ذهنم به کدامین آنها در لحظه، میدان می‌داد، بالا و پایین می‌شدم.
<br />
<br />
سعی کردم که نام دانشگاه را در نقشه تلفن وارد کنم، اما انگار همه اسامی از ذهنم پاک شده بود. یعنی چه؟ مگر ممکن است که اسم دانشگاهی که بخاطر حضور بر سر کلاسش دیرم شده بود، از خاطرم گریخته باشد. اما گریخته بود و اطمینان خاطر دادنهای آن شیرین سخن، تمرکز فکری‌ام را دچار اختلال می کرد. اکنون از یکسو، ذهنم به دنبال نام دانشگاه می‌گشت و از سوی دیگر وجودم کششی دیوانه وار به سوی او داشت. کششی نه فقط از جنسِ جنسی، بلکه از نوع زمینی خشک که بوی باران را حس کرده باشد. بمانند یک دیابتی که فکر تکه‌ای شیرینی او را از خود بیخود کرده باشد. حلاوت در عالیترین شکل خود.
<br />
<br />
به یاد مادرم افتادم که دیابت داشت و در یکی از صندلی های جلوی اتوبوس نشسته بود. هر چه فکر کردم یادم نیامد که با او سوار اتوبوس شده باشم. اما در آن لحظه می‌دانستم که جایی در آن جلوها نشسته است. جایی که من بخاطر ازدحام داخل اتوبوس قادر به دیدنش نبودم. دلم هم نمی خواست که جایم را ترک کنم که ذرات وجودم در مرزی شیرین با آن دختر جوش خورده، آب شده و به هم آمیخته بود.
<br />
<br />
سرم در یک تکان با سرش تماس یافت و لحظاتی در همانجا متوقف ماند، که به عکس العمل سریع بوسه‌ای کوچک از سوی او بر گونه من ختم شد. دلم فرو نشست و پس از آن همه چیزم در درونم فرو ریخت. شدم مانند آن ساختمان قدیمی بلند که فیلم تخریبش را دیده بودم. بنایی که پس از انفجار از بالا به پایین به درون خود هوار شد.
<br />
<br />
به فکرم رسید قبل از آنکه جدایی حاصل آید، شماره‌ام را بر روی کاغذی نوشته و به او دهم. پوشه‌ای در دستم بود که نوک چند برگ در درون آن از سرش بیرون زده بود. می‌خواستم تکه‌ای از سر یکی از برگها را با دو انگشت ببرُم و بر رویش شماره‌ام را بنویسم اما نمی خواستم کاغذی که شاید مهم بوده باشد، را خراب کنم. پوشه را باز کردم و به کاغذ ها نگاه کردم. می‌دیدم و نمی‌دیدم. گرفتن تصمیم در پاره کردن تکه‌ای از هرکدام آنها برایم مشکل بود. بالاخره از گوشه یکی از آنها قطعه‌ای را کندم و با خودکاری که معلوم نبود از کجا در دستم قرار گرفت شروع به نوشتن کردم. آنقدر ریز می‌نوشتم که خودم هم قادر به خواندنش نبود و بعضی از اعداد را بد نوشتم.
<br />
<br />
بالاخره ۱۰ شماره را با تصحیح و خط خوردگی به دستش دادم اما او گفت که با تلفن میانه چندانی ندارد. یعنی می‌توانست آنقدر ایده‌آل باشد که تفکر خودم را برای خودم بازگو کرده باشد.
<br />
<br />
ظاهرا به ایستگاهم رسیدم. میانه اتوبوس خلوت شده بود. مادرم را در آن جلو دیدم که گفت باید پیاده شویم. خودم را از جا کندم. دوچرخه‌ام در میانه  اتوبوس نبود. دزدیده بودنش. مادرم گفت که تلفن او هم گم شده است. خشم سراپای وجودم را گرفت. به عقب نگاه کردم، دختر نبود. ناگهان حس کردم که همه چیز تا آن لحظه فریبی بود برای دزدیدن دوچره‌ام اما در عین حال فکر کردم که پس تلفن مادرم چطور؟ مادرم پیاده شد. من در اتوبوس ماندم تا به راننده شکایت کنم و یا لااقل شکایتی را در جایی ثبت کرده باشم. راننده پشت فرمان نبود. گویی برای تنفس بیرون رفته بود. ایستادم تا بیاید. همه چیز را فراموش کردم، دختر، مادرم و دانشگاه، همه از خاطرم محو شده بود. در آن لحظه حس از دست دادن دوچرخه و خشم ناشی از بازیچه قرار گرفتن تمامی وجودم را از درون می‌جوید. بازی خورده از چه؟ بیاد نمی‌آوردم. به خود دلداری دادم که دوچرخه‌ام زیاد هم جالب نبود و آنکس که آنرا دزدیده است، باید بزودی پولی را بابت تعمیرش هم خرج کند.
<br />
<br />
راننده اتوبوس آمد و من شکایتم را گفتم. دفتری از محفظه سمت چپ قسمت راننده بیرون آورد و شکایتم را ثبت کرد. و من خسته از تمامی ماجراها، مطمئن بودم که همسر و دو فرزندم از شنیدن گم کردن دوچرخه ام زیاد ناراحت نخواهند شد، چرا که دوچرخه زیاد جالبی هم نبود. به خانه رسیدم. دو فرزندم را در آغوش کشیدم. ماجرای گم شدن دوچرخه را گفتم. اهمیت ندادند، نه برای آنکه دوچرخه خوبی نبود، که اصلا اهمیتی برایشان نداشت. سرشان بر روی تلفنهایشان بود. همسرم آمد. همان دختر جوانی بود که در اتوبوس در کنارم بود، اما نه آنقدر جوان. گرد سفیدی بر موهایش نشسته بود. به خود در آینه نگاه کردم. پیر و خسته، آماده رفتن به خواب شدم.
<br />
۱۴ سپتامبر ۲۰۱۹
        </p>
        </Col>
        <Col>
        <Sidebar />
        </Col>
        </Row>
      </Container>
    </>
)
}
export default post3
