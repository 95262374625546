// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help

import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Form, Button, Container, Nav } from 'react-bootstrap';


function Contact() {
  const [state, handleSubmit] = useForm("xzbydevj");
  if (state.succeeded) {
      return <p>Thanks for your message</p>;
  }
  return (
    <>

      <Container id="contact" fluid="md" style={{marginTop:30}}>
      <div className="section-title">
            <h2 style={{textAlign:'center'}}>تماس</h2>
            <h6 style={{textAlign:'center'}}>
            برای تماس لطفا نام، ایمیل و پیغام خود را در زیر وارد کنید  
            </h6>
          </div>
          
      <Form onSubmit={handleSubmit}>
        <Form.Row>
      
      <Form.Group className="col-md-6" >
      <Form.Label>نام </Form.Label>
      <Form.Control placeholder="Name"
        id="name"
        type="text" 
        name="name"
      />
      <ValidationError 
        prefix="name" 
        field="name"
        errors={state.errors}
      /> </Form.Group>
      <Form.Group className="col-md-6" >
      <Form.Label>آدرس ایمیل</Form.Label>

      <Form.Control placeholder="Email"
        id="email"
        type="email" 
        name="email"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      </Form.Group>
      </Form.Row>

      <Form.Group className="col-md-12">
      <Form.Label>پیغام</Form.Label>

      <Form.Control as="textarea" rows="3"
        id="message"
        type="text"
        name="message"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
      </Form.Group>

      <Button type="submit" disabled={state.submitting}>
        Submit
      </Button>
    </Form>
    </Container>
    <Nav fill>
            <Nav.Item style={{alignItems:'flex', backgroundColor:'#21201F', color:'white', position:'fixed', bottom:0, width:"100%"}}>
              <h5 >©2021 YAVAR FOUNDATION</h5>
            </Nav.Item>
        </Nav>
    </>
  );
}


export default Contact;
