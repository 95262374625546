import React from 'react'
import {Container, Card, Row, Button, Col} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Sidebar from '../../SideBar'



const index=()=> {

  return(
    <>
    <Container fluid dir="rtl" id='feature' style={{fontFamily:"Calibri", fontSize:17.5, paddingRight:100}}>
      <Row style={{marginTop:20}}>
      <Col md={6}>
      <h5 style={{padding:5, backgroundColor:'grey',margin:12, color:'white'}}> داستانهای کوتاه</h5>
      <Row style={{marginBottom:10}}>
      <Card style={{ width: '18rem' }}>
       <a href="https://akhbar-rooz.com/?p=98102" target='_blank'><Card.Img variant="top" src="/imgs/dali.jpg" height="180"/></a>
        <Card.Body>
          <Card.Title>سالوادور دالی در توفان شن</Card.Title>
          <Card.Text style={{textAlign:"right"}}>تا قبل از آشنایی با مهدی اسم سالوادور دالی را نشنیده بودم. راجع به سورئالیسم چیزهایی خوانده بودم، اما زیاد نمی‌دانستم. به خودش مهدی (مِهتی) نقاش می گفتیم. می‌گفت سبکش بیشتر متمایل به دالیه اگر روزگار به او اجازه انتخاب در کشیدن طرحهای مورد علاقه‌اش را بدهد.
          </Card.Text>
          <Button href="https://akhbar-rooz.com/?p=98102" target='_blank' variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem', marginRight:20 }}>
       <Link to='phoenix'><Card.Img variant="top" src="/imgs/manWatchTv.jpg" height="180"/></Link>
        <Card.Body>
          <Card.Title>قاف مثل ققنوس و ... قُلی</Card.Title>
          <Card.Text style={{textAlign:"right"}}>تنها دلخوشی ققنوس مرگ است، برای آن که بتواند زاده شود ابتدا می خواهد که بمیرد. او هم فرزند خویشتن است، هم والد خویش و هم وارث خود. هم دایه است و هم طفل. در واقع او خودش است ولی نه همان خود، زیرا او ابدیت حیات را از برکت مرگ به دست آورده است.
          </Card.Text>
          <Button href="phoenix" variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem', marginRight:20}}>
       <Link to='couch'><Card.Img variant="top" src="/imgs/couch.jpg" height="180" /></Link>
        <Card.Body>
          <Card.Title>پوست دست یا چرم روی مبل، کدامیک؟</Card.Title>
          <Card.Text>اولین تصویری که با دیدن چنین تیتری در ذهن هر کس ممکن است پدید بیاید، پوست انسانی است که مانند چرم شده. شاید انسانی که زیاد زیر آفتاب ایستاده و یا پیری و گذشت روزگار آنچنان مهر خود را بر جلد انسان کوبیده است که چرم نزدیکترین
          </Card.Text>
          <Button href="couch" variant="primary">ادامه داستان</Button>
          
        </Card.Body>
      </Card>
      </Row>
      <Row style={{marginBottom:10}}>
      <Card style={{ width: '18rem', marginLeft:20 }}>
        <Link to='snake'><Card.Img variant="top" src="/imgs/snake.png" /></Link>
        <Card.Body>
          <Card.Title>خواب مار</Card.Title>
          <Card.Text style={{textAlign:"right"}}>
          عجیب بود. خواب عجیبی بود. از آن خوابهایی که صبح پس از بیدار شدن تا ظهر هنوز به یاد انسان می ماند. و یا در بعداظهر با کمی فشار به یاد می آید. خواب مار بود. یادم نمی آید که مار در دستان کدامیک از آشنایان بود.
          </Card.Text>
          <Button href="snake" variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem', marginLeft:20 }}>
        <Link to='moonlight'><Card.Img variant="top" src="/imgs/bedroom.jpg" height="180" /></Link>
        <Card.Body>
          <Card.Title>مهتابی</Card.Title>

          <Card.Text>
          چه زود هیجان اولیه آشنایی و سپس ازدواج تمام شده بود. سه سالی از ازدواجمان می¬گذشت و عشق سرجایش بود، اما هیجان رفته بود. تا آنکه در آن شب مهتابی ابراز احساسات بی سابقه ما در حین
          </Card.Text>
          <Button href="moonlight" variant="primary">ادامه داستان</Button>
          
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src="/imgs/star.jpg" height="180"/>
        <Card.Body>
          <Card.Title>در پنجاه کلمه</Card.Title>
          <Card.Text>
          نیمه های شب از بالکن به آسمان می نگرم. همسر و فرزندانم در خوابند. خط روشنی در آسمان. قوس شهاب و وقت آرزو کردن. از دست نباید داد. ثروت. و ده ثانیه بعد صدای انفجاری مهیب در فضا می پیچد. به قارچ عظیم دود در افق می نگرم. چرا برای امنیت بشر آرزو نکردم؟
          </Card.Text>
          <Button variant="primary">پایان</Button>
        </Card.Body>
      </Card>
            
      </Row>
      <Row>
      <Card style={{ width: '18rem' }}>
        <Link to='life'><Card.Img variant="top" src="/imgs/life.jpg" height="180"/></Link>
        <Card.Body>
          <Card.Title>زندگی</Card.Title>
          <Card.Text>
          سوار بر دوچره، رکاب زنان به دنبال پسری که با سرعت در جلوی من حرکت می‌کرد، به سوی مدرسه می‌رفتیم. دیر شده بود و من عقب و عقب تر می‌افتادم. در میان درختان پا می‌زدیم و پس از مدتی دیگر ندیدمش.
          </Card.Text>
          <Button href="life" variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
            
      <Card style={{ width: '18rem', marginRight:20}}>
       <Link to='asghari'><Card.Img variant="top" src="/imgs/fish.jpeg" /></Link>
        <Card.Body>
          <Card.Title>اصغری</Card.Title>
          <Card.Text style={{textAlign:"right"}}>
          از شب عید روی طاقچه مانده است. یک ماهی قرمز کوچولو با چند بال در اطراف که مدام تکانشان می دهد. جایش را کمی بزرگتر کردم اما همش تو فکرش هستم.
          </Card.Text>
          <Button href="asghari" variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
      <Card style={{ width: '18rem', marginRight:20 }}>
       <a href="https://akhbar-rooz.com/?p=99942" target='_blank'><Card.Img variant="top" src="https://i2.wp.com/www.akhbar-rooz.com/wp-content/uploads/2021/01/798.jpg?w=500&ssl=1" height="180"/></a>
        <Card.Body>
          <Card.Title>دیوار</Card.Title>
          <Card.Text style={{textAlign:"right"}}>دقیقا نمی دانم چند روز پیش بود که او را دیدم. تازه از دیوار گذشته بود. احساس کردم که او خود من است. از پشت و کمی از بالا می‌دیدمش. به دیوار تکیه زده و خسته بنظر می‌رسید.
          </Card.Text>
          <Button href="https://akhbar-rooz.com/?p=99942https://akhbar-rooz.com/?p=99942" target='_blank' variant="primary">ادامه داستان</Button>
        </Card.Body>
      </Card>
      </Row>
      </Col>
      <Col md={6} style={{}}>
        <Sidebar />
      </Col>
      </Row>
    </Container>
    </>
)
}
export default index